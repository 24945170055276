'use client';
import Page from 'components/ui/page';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import useRouterStuff from 'hooks/useRouterStuff';

export default function LandingPageTabs() {
  const { t } = useTranslation();
  const { pathname } = useRouterStuff();

  const currentTab = pathname?.split('/')[1] || 'partner';

  const tabs = [
    {
      name: 'partner',
      href: '/',
      label: t('consulting_services_finder_capi')
    },
    {
      name: 'marketplace',
      href: '/marketplace',
      label: t('marketplace')
    },
    {
      name: 'knights',
      href: '/knights',
      label: t('knights')
    },
    {
      name: 'events',
      href: '/events',
      label: t('events')
    },
    {
      name: 'useCases',
      href: '/useCases',
      label: t('use_cases')
    },
    {
      name: 'cloud_svcs',
      href: '/cloud_svcs',
      label: t('csp_finder')
    }
  ];

  return (
    <nav className="bg-[#ffffff00]">
      <Page>
        <div className="inline-flex w-full overflow-x-auto m-0 p-0 mb-[-1px]">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              className={cn(
                'z-[900] flex-shrink-0 list-none py-0 px-8 m-0 hover:cursor-pointer hover:text-white',
                'h-[3.125rem] py-0 px-8 flex items-center justify-center rounded-0 text-base text-[#ffffffb0] font-normal no-underline',
                'border-[#2360a033] border-solid border-2',
                'first:rounded-tl-[7px] first:border-0 first:border-r-[0.25px]',
                'last:rounded-tr-[7px] last:border-0 last:border-l-[0.25px]',
                tab.name === currentTab
                  ? 'bg-white hover:bg-white text-[#2360a0] hover:text-[#2360a0] pointer-events-none'
                  : 'bg-[#FFFFFF33] text-[#ffffffcc]'
              )}
              style={{
                borderColor: '',
                borderWidth: '0 0.25px 0 0.25px'
              }}
              href={tab.href || ''}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </Page>
    </nav>
  );
}
